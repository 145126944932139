import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TematismoElencoComponent } from './tematismo-elenco/tematismo-elenco.component';
import { TematismoSchedaComponent } from './tematismo-scheda/tematismo-scheda.component';
import { ComponentsModule } from 'src/app/components/components.module';



@NgModule({
  declarations: [
    TematismoElencoComponent,
    TematismoSchedaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
  ]
})
export class TematismoModule { }
