import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { CampiSeoService } from 'src/app/components/scheda/campi-seo/campi-seo.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { GenericItem } from 'src/app/components/class/generic-item';
import { Percorso } from '../percorso';
import { PercorsiService } from '../percorsi.service';
import { forkJoin } from 'rxjs';
import { Sala, VideoPerSala } from '../elenco-video';

@Component({
  selector: 'app-percorsi-scheda',
  templateUrl: './percorsi-scheda.component.html',
  styleUrls: ['./percorsi-scheda.component.scss']
})
export class PercorsiSchedaComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: Percorso;
  titoloScheda: string = "Caricamento...";
  arrayData: any[any];
  linkBase: string;
  arrayTematismi: GenericItem[];
  arrayPubblico: GenericItem[];
  arrayVideo: VideoPerSala[];
  arraySale: Sala[];
  lang: string = "";

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private service: PercorsiService,
    public mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'titolo' : {'id' : 'titolo', 'nome': 'Titolo', 'lingue': this.mainEngineService.LINGUE, 'required': true},
      'immagine' : {'id' : 'immagine', 'nome': 'Immagine', 'immagineAttuale': '', 'type': 'IMG', 'required': true},
      'idTematismo' : {'id' : 'idTematismo', 'nome': 'Tematismo', 'type': 'SEL', 'required': true},
      'idPubblico' : {'id' : 'idPubblico', 'nome': 'Pubblico', 'type': 'SEL', 'required': true},
      'testo' : {'id' : 'testo', 'nome': 'Testo breve', 'type': 'TXA', 'lingue': this.mainEngineService.LINGUE, 'required': true},
      'rich_text' : {'id' : 'rich_text', 'nome': 'Testo completo', 'type': 'RTX', 'lingue': this.mainEngineService.LINGUE, 'required': true},
      'durata' : {'id' : 'durata', 'nome': 'Durata', 'type': 'ORA', 'required': true},
      'controlloPortaPrincipale' : {'id' : 'controlloPortaPrincipale', 'nome': 'Sblocco porta principale', 'type': 'BOL0', 'required': false},
    };
  }

  ngOnInit(): void {

    this.formComponentsService.activateLoader(true);
    this.linkBase = this.service.linkBase;

    this.route.params.subscribe(
      (params) => {
        forkJoin([
          this.service.getElencoTematismi(),
          this.service.getElencoPubblico(),
          this.service.getElencoVideo()
        ])
        .subscribe(
          ([arrayTematismi, arrayPubblico, arrayVideoSale]) => {
          this.arrayTematismi = arrayTematismi;
          this.arrayPubblico = arrayPubblico;
          this.arrayVideo = arrayVideoSale.videoPerSala;
          this.arraySale = arrayVideoSale.sale;

          for (var sala of this.arraySale) {
            this.arrayCampi['idVideo_' + sala.idSala] = {'id' : 'idVideo_' + sala.idSala, 'nome': 'Video ' + sala.denominazione, 'type': 'SEL', 'multiple': false, 'required': false, 'placeholder': 'Nessun video per questa sala'}
          }

          this.myForm = this.formComponentsService.createForm(this.arrayCampi);

          if (params.id != '0') {
            this.linkBase += '/lastItem/' + params.id;
            this.service.getItem(params.id)
            .subscribe(data => {
              this.myItem = data;
              this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

              this.formComponentsService.activateLoader(false);
              this.titoloScheda = "Modifica elemento - '" + this.myItem._titoloScheda + "'";
//              this.myItem.idTematismo = this.myItem.idTematismo.toString();
//              this.myItem.idPubblico = this.myItem.idPubblico.toString();
              this.myForm.patchValue(this.myItem);
              this.myForm.markAsPristine();
            },
            (error) => {
              this.loading = false;
              this.mainEngineService.errorHandler(error);
            });
          }
          else {
            this.titoloScheda = "Inserimento elemento";
            this.formComponentsService.activateLoader(false);
          }
        },
        (error) => {
          this.mainEngineService.errorHandler(error);
        });
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      });

  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.linkBase]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.service.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onBack(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  onSave() {
    this.onSubmit(2);
  }

  onChangeLang(lang) {
    this.lang = lang;
  }
}
