import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqElencoComponent } from './faq-elenco/faq-elenco.component';
import { FaqSchedaComponent } from './faq-scheda/faq-scheda.component';
import { FaqCappelloComponent } from './faq-cappello/faq-cappello.component';
import { FaqCategorieElencoComponent } from './faq-categorie-elenco/faq-categorie-elenco.component';
import { FaqCategorieSchedaComponent } from './faq-categorie-scheda/faq-categorie-scheda.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    FaqElencoComponent,
    FaqSchedaComponent,
    FaqCappelloComponent,
    FaqCategorieElencoComponent,
    FaqCategorieSchedaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NgbModule
  ]
})
export class FaqModule { }
