import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ComponentsService } from 'src/app/components/component.service';
import { CalendarOptions, EventClickArg, EventApi, FullCalendarComponent } from '@fullcalendar/angular';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { LoaderService } from 'src/app/service/loader.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { CalendarioService } from '../calendario.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CalendarioComponent implements OnInit {
  titoloScheda: string;
  calendarOptions: CalendarOptions;
  tipologia: string = "B";
  testataButtons: any[] = [];
  openVisualizzaDettaglio: number = 0;

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  constructor(
    private componentsService: ComponentsService,
    private loader: LoaderService,
    private notificationsService: NotificationsService,
    private mainEngineService: MainEngineService,
    private service: CalendarioService,
    private router: Router,
  ) {
    this.testataButtons = [
      {
        type: 'custom',
        testo: 'Parametri',
        colorClass: 'info',
        icon: 'fas fa-tools',
        actionTooltip: 'Gestione parametri',
        ML: true,
        click: this.gestioneParametri.bind(this),
      },
      {
        type: 'gestione',
        colorClass: 'success',
        ML: true,
        click: this.gestioneCalendario.bind(this),
      }
    ]
  }

  ngOnInit(): void {
    this.titoloScheda = "Gestione calendario";

    let that = this;
    this.calendarOptions = {
      headerToolbar: {
        left: 'dayGridMonth,timeGridWeek,timeGridDay',
        center: 'title',
        right: 'prevYear,prev,next,nextYear'
      },
      views: {
        timeGridDay: {
          buttonText: 'Giorno'
        },
        timeGridWeek: {
          buttonText: 'Settimana'
        },
        dayGridMonth: {
          buttonText: 'Mese'
        }
      },

      timeZone: 'Europe/Rome',
      locale: 'it',
      weekNumbers: true,
      weekNumberCalculation: 'ISO',
      nowIndicator: true,
      slotLabelFormat: {hour: '2-digit', minute: '2-digit'},
      allDaySlot: false,
      navLinks: true,

      initialView: "timeGridWeek",
      themeSystem: "bootstrap",
      weekends: true,
      editable: false,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      handleWindowResize: true,
      height: 'auto',

      eventDidMount: function(info) {
        that.patchCalendar();
      },

      datesSet(dateInfo ) {
          setTimeout(function() {
          that.patchCalendar();
        }, 300);
      },

      eventClick( info ) {
        that.openVisualizzaDettaglio = info.event.extendedProps.id;
      }



    };
    this.getParametri();
  }

  patchCalendar() {
    $('.fc-event-title').each(function() {
      let stringa: string = $(this).html();
      if (stringa.indexOf("---") >= 0) {
        stringa = stringa.substring(0, stringa.indexOf("---"));
      }
      stringa = stringa.split('{{').join('<');
      stringa = stringa.split('}}').join('>');
      $(this).html("");
      $(this).html(stringa);
    });
  }

  getParametri(): void {
    this.service.getParametriCalendario()
    .subscribe(data => {
      if (data.empty) {
        $(".calendarBody").hide();
        $(".noCalendar").show();
        this.loader.spin.next(false);
      }
      else {
        $(".noCalendar").hide();
        $(".calendarBody").show();
        this.calendarOptions.slotMinTime = data.orarioMin;
        this.calendarOptions.slotMaxTime = data.orarioMax;
        this.calendarOptions.slotDuration = '00:15:00';
        this.calendarOptions.slotLabelInterval = '00:15:00';

        this.calendarOptions.events = this.mainEngineService.baseUrl + 'calendario/' + this.tipologia;
      }
    }, error => {
      this.notificationsService.show('error', '', error.error.message);
      this.loader.spin.next(false);
    });
  }

  gestioneParametri() {
    this.router.navigate(["/calendario/parametri"])
  }

  gestioneCalendario() {
    this.router.navigate(["/calendario/gestione"])
  }

  resetOpenIdModal() {
    this.openVisualizzaDettaglio = 0;
  }
}
