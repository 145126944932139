import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoService } from '../video.service';

@Component({
  selector: 'app-video-elenco',
  templateUrl: './video-elenco.component.html',
  styleUrls: ['./video-elenco.component.scss']
})
export class VideoElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;

  constructor(
    private service: VideoService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo_ita}` },
          { columnDef: 'sala', header: 'Sala',    cell: (element: any) => `${element.sala}`},
          { columnDef: 'durata', header: 'Durata',    cell: (element: any) => `${element.durata}`},
        ];
        this.options = {
          linkBase: this.myService.linkBase
        }
      });
  }

  ngOnInit(): void {
  }

}
