import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaleElencoComponent } from './sale-elenco/sale-elenco.component';
import { SaleSchedaComponent } from './sale-scheda/sale-scheda.component';
import { ComponentsModule } from 'src/app/components/components.module';



@NgModule({
  declarations: [
    SaleElencoComponent,
    SaleSchedaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule
  ]
})
export class SaleModule { }
