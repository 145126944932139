import { Component, OnInit } from '@angular/core';
import { FaqCategorieService } from '../faq-categorie.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-faq-categorie-elenco',
  templateUrl: './faq-categorie-elenco.component.html',
  styleUrls: ['./faq-categorie-elenco.component.scss']
})
export class FaqCategorieElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;
  backMain: string = "/faq";

  constructor(
    private service: FaqCategorieService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.myService = this.service;
    let that = this;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo_ita}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase
        }
      });
  }

  ngOnInit(): void {
  }

  gestioneCappello() {
    this.router.navigate(["/percorsi/cappello"])
  }

  gestioneBiglietti(idPercorso) {
    this.router.navigate(["/percorsi/biglietteria/" + idPercorso])
  }
}
