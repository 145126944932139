import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PercorsiService } from '../percorsi.service';

@Component({
  selector: 'app-percorsi-elenco',
  templateUrl: './percorsi-elenco.component.html',
  styleUrls: ['./percorsi-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PercorsiElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;

  constructor(
    private service: PercorsiService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.myService = this.service;
    let that = this;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo_ita}` },
          { columnDef: 'tematismo', header: 'Tematismo',    cell: (element: any) => `${element.tematismo}`},
          { columnDef: 'pubblico', header: 'Pubblico',    cell: (element: any) => `${element.pubblico}`},
          { columnDef: 'durata', header: 'Durata',    cell: (element: any) => `${element.durata}`},
          { columnDef: 'nVideo', header: 'N. video',    cell: (element: any) => `${element.nVideo}`},
          { columnDef: 'controlloPortaPrincipaleTxt', header: 'Contr. porta',    cell: (element: any) => `${element.controlloPortaPrincipaleTxt}`},
        ];
        this.options = {
          linkBase: this.myService.linkBase
          ,customButtons: {
            a1_opzioni: {
              type: 'custom',
              testo: 'Biglietti',
              colorClass: 'success',
              icon: 'fas fa-ticket-alt',
              actionTooltip: 'Gestione biglietti per questo percorso',
              onClickFunction: this.gestioneBiglietti.bind(this),
              thisPointer: that,
              ML: true
            },
          }
          ,testataButtons: {
            a0_cappello: {
              type: 'custom',
              testo: 'Testo a cappello',
              colorClass: 'info',
              icon: 'fas fa-edit',
              actionTooltip: 'Gestione testo a cappello',
              ML: true,
              click: this.gestioneCappello.bind(this),
            },
          }
        }
      });
  }

  ngOnInit(): void {
  }

  gestioneCappello() {
    this.router.navigate(["/percorsi/cappello"])
  }

  gestioneBiglietti(idPercorso) {
    this.router.navigate(["/percorsi/biglietteria/" + idPercorso])
  }
}
