import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarioService } from '../calendario.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { SlotCalendario } from '../../common-items/slot-calendario';
import { FormGroup } from '@angular/forms';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-calendario-modal',
  templateUrl: './calendario-modal.component.html',
  styleUrls: ['./calendario-modal.component.scss']
})
export class CalendarioModalComponent implements OnInit {
  @Input() openId: number;
  @Output() resetOpenId = new EventEmitter();
  @Output() onSubmitEmitter = new EventEmitter();
  @ViewChild('modal') modal: any;

  modalReference: any;
  waitModalContent: boolean = true;
  modalTitle: string = "";
  arrayCampi: any[any] = {};
  slotCalendario: SlotCalendario;
  myForm: FormGroup;
  alertDisponibilita: boolean = false;
  loading: boolean = false;

  constructor(
    private modalService: NgbModal,
    private service: CalendarioService,
    private mainEngineService: MainEngineService,
    private formComponentsService: FormInputService,
  ) {
    this.arrayCampi = {
      'id' : {'id' : 'id'},
      'cognome' : {'id' : 'cognome', 'nome': 'Cognome', 'required': true},
      'nome' : {'id' : 'nome', 'nome': 'Nome', 'required': true},
      'email' : {'id' : 'email', 'nome': 'Email', 'required': false},
      'telefono' : {'id' : 'telefono', 'nome': 'Telefono', 'required': false},
    };

  }

  ngOnInit(): void {
  }

  onCancel() {
    this.waitModalContent = true;
    this.resetOpenId.emit(0);
    this.modalReference.close();
  }

  showContent() {
    if (this.openId <= 0) {
      return;
    }
    this.modalReference = this.modalService.open(this.modal, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});

    this.service.getSlotCalendario(this.openId)
    .subscribe(data => {
      this.slotCalendario = data;
      this.modalTitle = "Prenotazione per " + this.slotCalendario.percorso + " per il " + this.slotCalendario.data + " ore " + this.slotCalendario.orario;

      this.slotCalendario.tariffe.forEach( (element) => {
        this.arrayCampi['tariffa_' + element.id] = {'id' : 'tariffa_' + element.id, 'nome': element.denominazione + ' | ' + element.prezzoTxt , 'type': 'NUM', 'required': false}
      });
      this.myForm = this.formComponentsService.createForm(this.arrayCampi);


      this.waitModalContent = false;
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
  }

  onChangeTariffa(idTariffa, event) {
    let totale = 0;
    this.slotCalendario.tariffe.forEach( (element) => {
      if (this.myForm.controls['tariffa_' + element.id].value) {
        totale += parseInt(this.myForm.controls['tariffa_' + element.id].value);
      }
    });

    this.alertDisponibilita = (totale > this.slotCalendario.disponibilita);
  }

  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendPrenotazione(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.onSubmitEmitter.emit(null);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('openId' in changes) {
      if (this.openId > 0) {
        this.showContent();
      }
    }
  }
}
