import { Component, OnInit } from '@angular/core';
import { SaleService } from '../sale.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sale-elenco',
  templateUrl: './sale-elenco.component.html',
  styleUrls: ['./sale-elenco.component.scss']
})
export class SaleElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;

  constructor(
    private service: SaleService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo_ita}` },
          { columnDef: 'controlloPortaTxt', header: 'Contr. porta',    cell: (element: any) => `${element.controlloPortaTxt}`},
        ];
        this.options = {
          linkBase: this.myService.linkBase
        }
      });
  }

  ngOnInit(): void {
  }

}
