import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PercorsiService } from '../percorsi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PercorsiBiglietteriaService } from '../percorsi-biglietteria.service';

@Component({
  selector: 'app-percorsi-elenco-tariffe',
  templateUrl: './percorsi-elenco-tariffe.component.html',
  styleUrls: ['./percorsi-elenco-tariffe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PercorsiElencoTariffeComponent implements OnInit {
  lastId: number = 0;
  backUrl: string;
  options: any[any];
  columns: any[any];
  backMain: string = "/percorsi";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public service: PercorsiBiglietteriaService
  ) {
    this.columns = [
      { columnDef: 'denominazione', header: 'Denominazione',    cell: (element: any) => `${element.denominazione}` },
      { columnDef: 'prezzo', header: 'Prezzo', currency: 'EUR',    cell: (element: any) => `${element.prezzo}` },
      { columnDef: 'isAttivo', header: 'Attivo',    cell: (element: any) => `${element.isAttivo}` },
      { columnDef: 'proxVariazione', header: 'Prox. variazione',   cell: (element: any) => `${element.proxVariazione}`},
    ];

    this.route.params.subscribe(
      (params) => {
        this.service.idPercorso = params.idPercorso;
        if (params.id) {
          this.lastId = params.id;
        }
        this.backUrl = "/percorsi/biglietteria/" + this.service.idPercorso;
    });
  }

  ngOnInit(): void {
    this.options = {
      linkBase: this.backUrl
      ,customButtons: {
        a2_prezzi: {
          type: 'custom',
          testo: 'Gestione',
          colorClass: 'info',
          icon: 'fas fa-cogs',
          actionTooltip: 'Gestione variazione prezzi e disponibilità tariffa',
          ML: true,
          routerLink: 'prezzi',
        }
      }

    }
  }

}
