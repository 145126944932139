import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { elencoTariffe, Tariffa } from '../common-items/tariffa';
import { VariazioneTariffa } from '../common-items/variazione-tariffa';
import { StatoTariffa } from '../common-items/stato-tariffa';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';

@Injectable({
  providedIn: 'root'
})
export class PercorsiBiglietteriaService {
  idPercorso: number;
  apiUrl: string;
  linkBase: string = "biglietteria";
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;
  arrayCampi: any[any];

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + '/' + this.linkBase + '/';
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'denominazione' : {'id' : 'denominazione', 'nome': 'Denominazione', 'lingue': this.mainEngineService.LINGUE, 'required': true},
      'descrizione' : {'id' : 'descrizione', 'nome': 'Descrizione', 'lingue': this.mainEngineService.LINGUE, 'type': 'TXA'},
      'idPercorso' : {'id' : 'idPercorso', 'nome': 'Percorso', 'type': 'NUM', 'required': true},
      'prezzo' : {'id' : 'prezzo', 'nome': 'Prezzo iniziale', 'type': 'VLT', 'required': true},
      'attivo' : {'id' : 'attivo', 'nome': 'Attivo subito', 'type': 'CHK', 'required': false},
    };
  }

  getElenco(): Observable<elencoTariffe> {
    return this.http.get<elencoTariffe>(this.apiUrl + "?op=elencoTariffe&idPercorso=" + this.idPercorso)
  }

  getElencoVariazioni(idTariffa: number): Observable<VariazioneTariffa[]> {
    return this.http.get<VariazioneTariffa[]>(this.apiUrl + "?op=elencoVariazioni&idTariffa=" + idTariffa)
  }

  getStatoTariffa(idTariffa: number): Observable<StatoTariffa[]> {
    return this.http.get<StatoTariffa[]>(this.apiUrl + "?op=andamentoTariffa&idTariffa=" + idTariffa)
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + 'op/opzioni?idPercorso=' + this.idPercorso)
  }

  getItem(id: number): Observable<Tariffa> {
    return this.http.get<Tariffa>(this.apiUrl + id)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  deleteItem(idTariffa: number) {
    return this.http.delete(this.apiUrl + "?op=tariffa&id=" + idTariffa)
  }


  getVariazione(idVariazione: number): Observable<VariazioneTariffa> {
    return this.http.get<VariazioneTariffa>(this.apiUrl + "?op=variazioneTariffa&id=" + idVariazione)
  }

  sendVariazione(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl + 'op/variazione' , body);
  }

  deleteVariazione(idVariazione: number) {
    return this.http.delete<VariazioneTariffa>(this.apiUrl + "?op=variazioneTariffa&id=" + idVariazione)
  }

  toggleAttivo(id: number) {
    const tipologia = 'attivo';
    return this.http.patch<any>(this.apiUrl + id, {tipologia})
  }
}
