import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Tariffa } from '../../common-items/tariffa';
import { ActivatedRoute, Router } from '@angular/router';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { PercorsiBiglietteriaService } from '../percorsi-biglietteria.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ComponentsService } from 'src/app/components/component.service';
import { forkJoin, of } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-percorsi-scheda-tariffa',
  templateUrl: './percorsi-scheda-tariffa.component.html',
  styleUrls: ['./percorsi-scheda-tariffa.component.scss']
})
export class PercorsiSchedaTariffaComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  myItem: Tariffa;
  titoloScheda: string = "Caricamento...";
  linkBase: string;
  arrayCampi: any[any];
  modifica: boolean = false;
  lang: string = "";

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    public service: PercorsiBiglietteriaService,
    public mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = this.service.arrayCampi;
  }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.service.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.route.params.subscribe(
      (params) => {
        this.service.idPercorso = params.idPercorso;
        this.myForm.controls.idPercorso.setValue(this.service.idPercorso);
        this.linkBase = "/percorsi/biglietteria/" + this.service.idPercorso;

        let sources = [];
        if (params.id != '0') {
          sources.push(this.service.getItem(params.id));
        }

        forkJoin({
          data: params.id != '0'
            ? this.service.getItem(params.id)
            : of(null)
          })
         .subscribe(({
          data
          }) => {
            if (data) {
              this.modifica = true;
              this.myItem = data;
              this.myForm.patchValue(this.myItem);
              this.myForm.markAsPristine();
              this.titoloScheda = "Modifica elemento - '" + this.myItem.denominazione + "'";
            }
            else {
              this.titoloScheda = "Inserimento elemento";
              this.myItem = new Tariffa;
            }
            this.formComponentsService.activateLoader(false);
         });

/*
        if (params.id != '0') {
          this.linkBase += '/lastItem/' + params.id;
          this.service.getItem(params.id)
          .subscribe(data => {
            this.myItem = data;
            this.titoloScheda = "Modifica elemento - '" + this.myItem.denominazione + "'";
            this.formComponentsService.activateLoader(false);
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
          });
        }
        else {
          this.titoloScheda = "Inserimento elemento";
          this.myItem = new Tariffa;
        }
*/
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      });
  }


  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.linkBase + '/lastItem/' + response]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.service.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  formReady(ready: boolean) {
    this.formComponentsService.activateLoader(!ready);
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  onSave() {
    this.onSubmit(2);
  }

  onChangeLang(lang) {
    this.lang = lang;
  }

}
