import { Component, OnInit } from '@angular/core';
import { TraduzioniWebappService } from '../traduzioni-webapp.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-traduzioni-webapp-elenco',
  templateUrl: './traduzioni-webapp-elenco.component.html',
  styleUrls: ['./traduzioni-webapp-elenco.component.scss']
})
export class TraduzioniWebappElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;

  constructor(
    private service: TraduzioniWebappService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'varname', header: 'Variabile',    cell: (element: any) => `${element.varname}` },
          { columnDef: 'ita', header: 'Testo ITA',    cell: (element: any) => `${element.ita}`},
          { columnDef: 'eng', header: 'Testo ENG',    cell: (element: any) => `${element.eng}`},
        ];
        this.options = {
          linkBase: this.myService.linkBase
        }
      });
  }

  ngOnInit(): void {
  }

}
