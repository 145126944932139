import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { JwtInterceptorService } from './service/jwt-interceptor.service';
import { ErrorInterceptorService } from './service/error-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import * as $ from 'jquery';

import { LocalLoginLayoutComponent } from './custom/layouts/local-login-layout/local-login-layout.component';
import { LocalMainLayoutComponent } from './custom/layouts/local-main-layout/local-main-layout.component';
import { ComponentsModule } from './components/components.module';
import { NgbDropdownModule, NgbModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { MainLayoutModule } from './components/layouts/main-layout/main-layout.module';
import { MainLayoutComponent } from './components/layouts/main-layout/main-layout.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { ReservedAccessModule } from './components/reservedAccess/reserved-access.module';
import { AnagraficaModule } from './pages/anagrafica/anagrafica.module';
import { MetodiPagamentoModule } from './pages/metodi-pagamento/metodi-pagamento.module';
import { PrenotazioniModule } from './pages/prenotazioni/prenotazioni.module';
import { ControlloPrenotazioneModule } from './pages/controllo-prenotazione/controllo-prenotazione.module';
import { TraduzioniWebappModule } from './pages/traduzioni-webapp/traduzioni-webapp.module';
import { VideoModule } from './pages/video/video.module';
import { PubblicoModule } from './pages/pubblico/pubblico.module';
import { TematismoModule } from './pages/tematismo/tematismo.module';
import { PercorsiModule } from './pages/percorsi/percorsi.module';
import { CalendarioModule } from './pages/calendario/calendario.module';
import { FaqModule } from './pages/faq/faq.module';
import { SaleModule } from './pages/sale/sale.module';

@NgModule({
  declarations: [
    AppComponent,
    LocalLoginLayoutComponent,
    LocalMainLayoutComponent,
    MainLayoutComponent,
    HomepageComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbDropdownModule,
    MainLayoutModule,
    ComponentsModule,
    ReservedAccessModule,
    MatTableModule,
    MatSortModule,
    AnagraficaModule,
    MetodiPagamentoModule,
    PrenotazioniModule,
    ControlloPrenotazioneModule,
    TraduzioniWebappModule,
    VideoModule,
    PubblicoModule,
    TematismoModule,
    PercorsiModule,
    CalendarioModule,
    FaqModule,
    SaleModule
  ],
  providers : [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
