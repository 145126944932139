import { MenuItem } from "src/app/components/modules/menu.model";

export const MENU: MenuItem[] = [
    {
      label: 'Dashboard',
      icon: 'fas fa-home',
      link: '/',
      strict: true
    },
    {
      label: 'Calendario',
      icon: 'fas fa-calendar-alt',
      link: '/calendario',
    },
    {
      label: 'Anagrafica',
      icon: 'fas fa-users',
      link: '/anagrafica',
    },
    {
      label: 'Metodi di pagamento',
      icon: 'far fa-credit-card',
      link: '/metodiPagamento',
    },
    {
      label: 'Prenotazioni',
      icon: 'fas fa-ticket-alt',
      link: '/prenotazioni',
    },
    {
      label: 'Controllo prenotazione',
      icon: 'fas fa-clipboard-check',
      link: '/controlloPrenotazione',
    },
    {
      label: 'CONTENUTI WEBAPP',
      isTitle: true
    },
    {
      label: 'Sale',
      icon: 'fas fa-person-booth',
      link: '/sale',
    },
    {
      label: 'Video',
      icon: 'fas fa-file-video',
      link: '/video',
    },
    {
      label: 'Percorsi',
      icon: 'fas fa-bezier-curve',
      link: '/percorsi',
    },
    {
      label: 'Impostazioni',
      icon: 'fas fa-tools',
      subItems: [
        {
          label: 'Tematismo',
          icon: 'fas fa-hand-pointer',
          link: '/tematismo',
        },
        {
          label: 'Pubblico',
          icon: 'fas fa-users',
          link: '/pubblico',
        },
      ]
    },
    {
      label: 'Faq',
      icon: 'fas fa-question-circle',
      link: '/faq',
    },
    {
      label: 'Traduzioni webapp',
      icon: 'fas fa-language',
      link: '/traduzioniWebapp',
    },
    {
      label: 'ABOUT',
      isTitle: true
    },
    {
      label: 'Info versione',
      icon: 'fas fa-info-circle',
      link: '/infoVersione',
    },
/*
    {
      label: 'Festività ricorrenti',
      icon: 'fas fa-church',
      link: '/festivita',
    },
*/
];
