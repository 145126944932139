import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FaqCategorie } from './faq-categorie';
import { HttpClient } from '@angular/common/http';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';

@Injectable({
  providedIn: 'root'
})
export class FaqCategorieService {
  apiUrl: string;
  linkBase: string = "faq/categorie";
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + '/categorieFaq/';
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');
  }

  getElenco(): Observable<FaqCategorie[]> {
    return this.http.get<FaqCategorie[]>(this.apiUrl)
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + 'op/opzioni')
  }

  getItem(id: number): Observable<FaqCategorie> {
    return this.http.get<FaqCategorie>(this.apiUrl + id)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  deleteItem(idTariffa: number) {
    return this.http.delete(this.apiUrl + "?id=" + idTariffa)
  }

  changePosition(id: number, newPos: number) {
    const tipologia = 'posizione';
    return this.http.patch<any>(this.apiUrl + id, {tipologia, newPos})
  }

}
