import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Parametri } from '../../common-items/parametri';
import { PercorsiService } from '../percorsi.service';

@Component({
  selector: 'app-percorsi-cappello',
  templateUrl: './percorsi-cappello.component.html',
  styleUrls: ['./percorsi-cappello.component.scss']
})
export class PercorsiCappelloComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  myItem: Parametri;
  titoloScheda: string = "Caricamento...";
  linkBase: string = "/percorsi";
  arrayCampi: any[any];
  lang: string = "";

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    public service: PercorsiService,
    public mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 1, 'type': 'HID'},
      'testo' : {'id' : 'testo', 'nome': 'Testo', 'type': 'TXA', 'lingue': this.mainEngineService.LINGUE, 'required': true},
    };
  }

  ngOnInit(): void {

    this.formComponentsService.activateLoader(true);
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);

    this.service.getCappello()
    .subscribe(data => {
      this.myItem = data;

      this.formComponentsService.activateLoader(false);
      this.titoloScheda = "Modifica testo a cappello";
      this.myForm.patchValue(this.myItem);
      this.myForm.markAsPristine();
    },
    (error) => {
      this.loading = false;
      this.mainEngineService.errorHandler(error);
    });

  }



  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendCappello(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.router.navigate([this.linkBase]);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  formReady(ready: boolean) {
    this.formComponentsService.activateLoader(!ready);
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onChangeLang(lang) {
    this.lang = lang;
  }
}
