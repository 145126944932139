import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PubblicoElencoComponent } from './pubblico-elenco/pubblico-elenco.component';
import { PubblicoSchedaComponent } from './pubblico-scheda/pubblico-scheda.component';
import { ComponentsModule } from 'src/app/components/components.module';



@NgModule({
  declarations: [
    PubblicoElencoComponent,
    PubblicoSchedaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
  ]
})
export class PubblicoModule { }
