import { Routes } from '@angular/router';
import { InfoVersioneComponent } from 'src/app/components/info-versione/info-versione.component';
import { Page404Component } from 'src/app/components/page404/page404.component';
import { ProfiloComponent } from 'src/app/components/profilo/profilo.component';
import { AnagraficaElencoComponent } from 'src/app/pages/anagrafica/anagrafica-elenco/anagrafica-elenco.component';
import { AnagraficaSchedaComponent } from 'src/app/pages/anagrafica/anagrafica-scheda/anagrafica-scheda.component';
import { CalendarioParametriComponent } from 'src/app/pages/calendario/calendario-parametri/calendario-parametri.component';
import { CalendarioSchedaComponent } from 'src/app/pages/calendario/calendario-scheda/calendario-scheda.component';
import { CalendarioComponent } from 'src/app/pages/calendario/calendario/calendario.component';
import { MainControlloComponent } from 'src/app/pages/controllo-prenotazione/main-controllo/main-controllo.component';
import { OpDoneComponent } from 'src/app/pages/controllo-prenotazione/op-done/op-done.component';
import { RicercaComponent } from 'src/app/pages/controllo-prenotazione/ricerca/ricerca.component';
import { ScansionaQrcodeComponent } from 'src/app/pages/controllo-prenotazione/scansiona-qrcode/scansiona-qrcode.component';
import { FaqCappelloComponent } from 'src/app/pages/faq/faq-cappello/faq-cappello.component';
import { FaqCategorieElencoComponent } from 'src/app/pages/faq/faq-categorie-elenco/faq-categorie-elenco.component';
import { FaqCategorieSchedaComponent } from 'src/app/pages/faq/faq-categorie-scheda/faq-categorie-scheda.component';
import { FaqElencoComponent } from 'src/app/pages/faq/faq-elenco/faq-elenco.component';
import { FaqSchedaComponent } from 'src/app/pages/faq/faq-scheda/faq-scheda.component';
import { HomepageComponent } from 'src/app/pages/homepage/homepage.component';
import { MetodiPagamentoElencoComponent } from 'src/app/pages/metodi-pagamento/metodi-pagamento-elenco/metodi-pagamento-elenco.component';
import { MetodiPagamentoSchedaComponent } from 'src/app/pages/metodi-pagamento/metodi-pagamento-scheda/metodi-pagamento-scheda.component';
import { PercorsiCappelloComponent } from 'src/app/pages/percorsi/percorsi-cappello/percorsi-cappello.component';
import { PercorsiElencoTariffeComponent } from 'src/app/pages/percorsi/percorsi-elenco-tariffe/percorsi-elenco-tariffe.component';
import { PercorsiElencoComponent } from 'src/app/pages/percorsi/percorsi-elenco/percorsi-elenco.component';
import { PercorsiPrezziTariffaComponent } from 'src/app/pages/percorsi/percorsi-prezzi-tariffa/percorsi-prezzi-tariffa.component';
import { PercorsiSchedaTariffaComponent } from 'src/app/pages/percorsi/percorsi-scheda-tariffa/percorsi-scheda-tariffa.component';
import { PercorsiSchedaComponent } from 'src/app/pages/percorsi/percorsi-scheda/percorsi-scheda.component';
import { PrenotazioniElencoComponent } from 'src/app/pages/prenotazioni/prenotazioni-elenco/prenotazioni-elenco.component';
import { PrenotazioniSchedaComponent } from 'src/app/pages/prenotazioni/prenotazioni-scheda/prenotazioni-scheda.component';
import { PubblicoElencoComponent } from 'src/app/pages/pubblico/pubblico-elenco/pubblico-elenco.component';
import { PubblicoSchedaComponent } from 'src/app/pages/pubblico/pubblico-scheda/pubblico-scheda.component';
import { SaleElencoComponent } from 'src/app/pages/sale/sale-elenco/sale-elenco.component';
import { SaleSchedaComponent } from 'src/app/pages/sale/sale-scheda/sale-scheda.component';
import { TematismoElencoComponent } from 'src/app/pages/tematismo/tematismo-elenco/tematismo-elenco.component';
import { TematismoSchedaComponent } from 'src/app/pages/tematismo/tematismo-scheda/tematismo-scheda.component';
import { TraduzioniWebappElencoComponent } from 'src/app/pages/traduzioni-webapp/traduzioni-webapp-elenco/traduzioni-webapp-elenco.component';
import { TraduzioniWebappSchedaComponent } from 'src/app/pages/traduzioni-webapp/traduzioni-webapp-scheda/traduzioni-webapp-scheda.component';
import { VideoElencoComponent } from 'src/app/pages/video/video-elenco/video-elenco.component';
import { VideoSchedaComponent } from 'src/app/pages/video/video-scheda/video-scheda.component';


export const MainLayoutRoutes: Routes = [
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: 'home',
    component: HomepageComponent
  },
  {
    path: '404',
    component: Page404Component
  },
  {
    path: 'calendario',
    component: CalendarioComponent
  },
  {
    path: 'calendario/gestione',
    component: CalendarioSchedaComponent
  },
  {
    path: 'calendario/parametri',
    component: CalendarioParametriComponent
  },
  {
    path: 'anagrafica/lastItem/:id',
    component: AnagraficaElencoComponent
  },
  {
    path: 'anagrafica',
    component: AnagraficaElencoComponent
  },
  {
    path: 'anagrafica/:id',
    component: AnagraficaSchedaComponent
  },
  {
    path: 'metodiPagamento',
    component: MetodiPagamentoElencoComponent
  },
  {
    path: 'metodiPagamento/lastItem/:id',
    component: MetodiPagamentoElencoComponent
  },
  {
    path: 'metodiPagamento/:id',
    component: MetodiPagamentoSchedaComponent
  },
  {
    path: 'prenotazioni',
    component: PrenotazioniElencoComponent
  },
  {
    path: 'prenotazioni/lastItem/:id',
    component: PrenotazioniElencoComponent
  },
  {
    path: 'prenotazioni/:id',
    component: PrenotazioniSchedaComponent
  },
  {
    path: 'controlloPrenotazione',
    component: MainControlloComponent
  },
  {
    path: 'controlloPrenotazione/qrcode/:idPrenotazione',
    component: ScansionaQrcodeComponent
  },
  {
    path: 'controlloPrenotazione/qrcode',
    component: ScansionaQrcodeComponent
  },
  {
    path: 'controlloPrenotazione/ricerca/:parametriRicerca',
    component: RicercaComponent
  },
  {
    path: 'controlloPrenotazione/ricerca',
    component: RicercaComponent
  },
  {
    path: 'controlloPrenotazione/opDone/:idPrenotazione/:securityCode',
    component: OpDoneComponent
  },
  {
    path: 'profilo',
    component: ProfiloComponent
  },
  {
    path: 'infoVersione',
    component: InfoVersioneComponent
  },
  {
    path: 'traduzioniWebapp',
    component: TraduzioniWebappElencoComponent
  },
  {
    path: 'traduzioniWebapp/lastItem/:id',
    component: TraduzioniWebappElencoComponent
  },
  {
    path: 'traduzioniWebapp/:id',
    component: TraduzioniWebappSchedaComponent
  },
  {
    path: 'sale',
    component: SaleElencoComponent
  },
  {
    path: 'sale/lastItem/:id',
    component: SaleElencoComponent
  },
  {
    path: 'sale/:id',
    component: SaleSchedaComponent
  },
  {
    path: 'video',
    component: VideoElencoComponent
  },
  {
    path: 'video/lastItem/:id',
    component: VideoElencoComponent
  },
  {
    path: 'video/:id',
    component: VideoSchedaComponent
  },
  {
    path: 'tematismo',
    component: TematismoElencoComponent
  },
  {
    path: 'tematismo/lastItem/:id',
    component: TematismoElencoComponent
  },
  {
    path: 'tematismo/:id',
    component: TematismoSchedaComponent
  },
  {
    path: 'pubblico',
    component: PubblicoElencoComponent
  },
  {
    path: 'pubblico/lastItem/:id',
    component: PubblicoElencoComponent
  },
  {
    path: 'pubblico/:id',
    component: PubblicoSchedaComponent
  },
  {
    path: 'percorsi',
    component: PercorsiElencoComponent
  },
  {
    path: 'percorsi/cappello',
    component: PercorsiCappelloComponent
  },
  {
    path: 'percorsi/lastItem/:id',
    component: PercorsiElencoComponent
  },
  {
    path: 'percorsi/biglietteria/:idPercorso',
    component: PercorsiElencoTariffeComponent
  },
  {
    path: 'percorsi/biglietteria/:idPercorso/lastItem/:id',
    component: PercorsiElencoTariffeComponent
  },
  {
    path: 'percorsi/biglietteria/:idPercorso/:id/prezzi',
    component: PercorsiPrezziTariffaComponent
  },
  {
    path: 'percorsi/biglietteria/:idPercorso/:id',
    component: PercorsiSchedaTariffaComponent
  },

  {
    path: 'percorsi/:id',
    component: PercorsiSchedaComponent
  },
  {
    path: 'faq',
    component: FaqElencoComponent
  },
  {
    path: 'faq/cappello',
    component: FaqCappelloComponent
  },
  {
    path: 'faq/lastItem/:id',
    component: FaqElencoComponent
  },
  {
    path: 'faq/categorie',
    component: FaqCategorieElencoComponent
  },
  {
    path: 'faq/categorie/lastItem/:id',
    component: FaqCategorieElencoComponent
  },
  {
    path: 'faq/categorie/:id',
    component: FaqCategorieSchedaComponent
  },
  {
    path: 'faq/:id',
    component: FaqSchedaComponent
  },

];

