import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PubblicoService } from '../pubblico.service';

@Component({
  selector: 'app-pubblico-elenco',
  templateUrl: './pubblico-elenco.component.html',
  styleUrls: ['./pubblico-elenco.component.scss']
})
export class PubblicoElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;

  constructor(
    private service: PubblicoService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo_ita}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase
        }
      });
  }

  ngOnInit(): void {
  }

}
