/******
Versione 1.0.20210401
******/

import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { FormInputService } from '../form-input.service';

@Component({
  selector: 'app-campo-testo',
  templateUrl: './campo-testo.component.html',
  styleUrls: ['./campo-testo.component.scss']
})
export class CampoTestoComponent implements OnInit {
  inputClass: string;
  noteClass: string;
  arrayDati: any[] = [];

  constructor(
    private formComponentsService: FormInputService
    ) {
      this.inputClass = this.formComponentsService.inputClass;
      this.noteClass = this.formComponentsService.noteClass;
  }

  @Input() myForm: FormGroup;
  @Input() config: any;
  @Input() lang: string = "";

  getInput(id) {
    return this.myForm.get(id);
  }


  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = 'testo';
    }
    if (this.config.nome == undefined) {
      this.config.nome = 'Testo';
    }
    if (!this.config.lingue) {
      this.config.lingue = false;
    }

    if (!this.config.lingue) {
      this.config.lingue = [''];
    }

    this.config.lingue.forEach(lingua => {
      let dummy : any = {};
      dummy.lingua = lingua;
      dummy.nome = this.config.nome;
      dummy.required = this.config.required;
      dummy.id = this.config.id;
      if (lingua != '') {
        dummy.nome += " " + lingua.toUpperCase();
        dummy.id += "_" + lingua;
        if (lingua != 'ita') {
          dummy.required = false;
        }
      }

      this.arrayDati.push(dummy);
    });

    if (!this.config.shortInput) {
      this.config.shortInput = false;
    }
    if (!this.config.uppercase) {
      this.config.uppercase = false;
    }
    if (!this.config.readonly) {
      this.config.readonly = false;
    }
    if (this.config.type == "CF") {
      this.config.uppercase = true;
    }
    if (this.config.type == "TIME") {
      this.config.shortInput = true;
    }

    if (this.config.shortInput) {
      this.inputClass = this.formComponentsService.shortInputClass;
      this.noteClass = this.formComponentsService.shortNoteClass;
    }
  }

  forceUppercaseConditionally(event) {
    if (this.config.uppercase) {
        this.myForm.get(this.config.id).setValue(event.target.value.toUpperCase());
    }
  }
}
