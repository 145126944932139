import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PercorsiElencoComponent } from './percorsi-elenco/percorsi-elenco.component';
import { PercorsiSchedaComponent } from './percorsi-scheda/percorsi-scheda.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { PercorsiCappelloComponent } from './percorsi-cappello/percorsi-cappello.component';

import { PercorsiElencoTariffeComponent } from './percorsi-elenco-tariffe/percorsi-elenco-tariffe.component';
import { PercorsiSchedaTariffaComponent } from './percorsi-scheda-tariffa/percorsi-scheda-tariffa.component';
import { PercorsiPrezziTariffaComponent } from './percorsi-prezzi-tariffa/percorsi-prezzi-tariffa.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    PercorsiElencoComponent,
    PercorsiSchedaComponent,
    PercorsiCappelloComponent,
    PercorsiElencoTariffeComponent,
    PercorsiSchedaTariffaComponent,
    PercorsiPrezziTariffaComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NgbModule
  ]
})
export class PercorsiModule { }
