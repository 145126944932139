import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoElencoComponent } from './video-elenco/video-elenco.component';
import { VideoSchedaComponent } from './video-scheda/video-scheda.component';
import { ComponentsModule } from 'src/app/components/components.module';



@NgModule({
  declarations: [
    VideoElencoComponent,
    VideoSchedaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
  ]
})
export class VideoModule { }
