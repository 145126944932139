import { Router, RoutesRecognized } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from '../components/service/notification.service';
import { LoaderService } from './loader.service';
import { ConfigParams } from '../components/class/config-params';
import { GenericItem } from '../components/class/generic-item';
import packageInfo  from '../../../package.json';


@Injectable({
  providedIn: 'root'
})
export class MainEngineService {
  public VERSION: string = packageInfo.version;

  globalUrlSubject: BehaviorSubject<string>;
  globalUrl: string;
  globalUrlArray: string[];
  globalPreviousUrlSubject: BehaviorSubject<string>;
  globalPreviousUrl: string;
  globalPreviousUrlArray: string[];
  baseUrl = '';
  commonUrl = 'https://webserver.dreamfactorydesign.com/api/';
  configParamsSubject: BehaviorSubject<ConfigParams>;
  userAnagrafica: any;
  connessioneAssenteObject: BehaviorSubject<boolean>;
  $screenW: BehaviorSubject<number>;

  readonly LINGUE = ["ita", "eng"];

  constructor(
    private router: Router,
    private http: HttpClient,
    private notificationsService: NotificationsService,
    private loader: LoaderService,
  ) {
    let versionModules = this.VERSION.split(".");

    this.globalUrl = '';
    this.globalUrlSubject = new BehaviorSubject<string>('');
    this.globalPreviousUrl = '';
    this.globalPreviousUrlSubject = new BehaviorSubject<string>('');
    this.configParamsSubject = new BehaviorSubject<ConfigParams>(null);
    this.$screenW = new BehaviorSubject<number>(0);

    this.baseUrl = "https://admin.museomultimedialemedievale.it";
    this.baseUrl += '/api/v' + versionModules[0] +'/';
  }

  onRouteChange(events: RoutesRecognized[]): void {
//    console.log('previous url', events[0].urlAfterRedirects);
//    console.log('current url', events[1].urlAfterRedirects);

    let url: string = events[1].urlAfterRedirects;

    this.updateUrl(url);

    url = events[0].urlAfterRedirects;
    if (url.indexOf('lastItem') > 0) {
      url = url.substring(0, url.indexOf('lastItem'));
    }

    this.globalPreviousUrl = url;
    this.globalPreviousUrlArray = url.split('/');
    this.globalPreviousUrlSubject.next(url);

    this.getConfigParams().subscribe(data => {
      this.configParamsSubject.next(data);
    });
  }


  updateUrl(url: string = "") {
    if (!url) {
      url = this.router.url;
    }

    if ((url === undefined) || (this.globalUrl === url)) {
      return;
    }

    this.globalUrl = url;
    this.globalUrlArray = url.split('/');
    this.globalUrlSubject.next(url);

    //    console.log(actualUrl);

  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

  getUrlSubject(): Observable<string> {
    return this.globalUrlSubject.asObservable();
  }

  getConfigParams(): Observable<ConfigParams> {
    return this.http.get<ConfigParams>(this.baseUrl + 'getConfigParams/');
  }

  getConfigParamsSubject(): Observable<ConfigParams> {
    return this.configParamsSubject.asObservable();
  }

  setScreenW$(value: number) {
    this.$screenW.next(value);

    if (value < 1400) {
      document.body.setAttribute("data-sidebar-size", "md");
    }
    else {
      document.body.setAttribute("data-sidebar-size", "lg");
    }

  }

  getScreenW$(): Observable<number> {
    return this.$screenW.asObservable();
  }

  toCurrency(string: any): string {
    return parseFloat(string).toFixed(2).replace(".", ",") + " €";
  }


  errorHandler(e) {
//    console.log(e);
    this.loader.stopSpinner();
    let errorTitle = '';
    let errorMsg = '';
    let errorType = 'error';
    if ((e.error.message !== undefined) && (e.error.message !== '')) {
      if ((e.error.type !== undefined) && (e.error.type !== '')) {
        errorType = e.error.type;
      }
      errorTitle = (e.error.title !== undefined) && (e.error.title !== '') ? e.error.title : errorType == "warning" ? 'ATTENZIONE!' : 'Errore di sistema';
      errorMsg = e.error.message;
    } else if (e.statusText === undefined) {
      if (e.status === 200) {
        errorTitle = 'Errore imprevisto';
        errorMsg = 'Si è verificato un errore imprevisto. Provare a ripetere l\'ultima operazione effettuata';
      } else {
        errorTitle = 'Errore di comunicazione';
        errorMsg = 'Verificare lo stato della connessione internet e riprovare';
        this.connessioneAssenteObject.next(true);
      }
    } else {
      errorMsg = e.statusText;
      if ((e.name !== undefined) && (e.name !== '')) {
        errorMsg = e.name + ": " + errorMsg;
      }
    }
    this.notificationsService.show(errorType, errorTitle, errorMsg);
  }

  getElencoStati(): Observable<GenericItem[]> {
    return this.http.get<GenericItem[]>(this.commonUrl + 'stati/')
  }

  getElencoProvince(): Observable<GenericItem[]> {
    return this.http.get<GenericItem[]>(this.commonUrl + 'province/?noId')
  }

}
