import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Sala } from './sala';

@Injectable({
  providedIn: 'root'
})
export class SaleService {
  apiUrl: string;
  linkBase: string = "sale";
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');
  }

  getElenco(): Observable<Sala[]> {
    return this.http.get<Sala[]>(this.apiUrl)
  }

  getOpzioni(): Observable<Sala> {
    return this.http.get<Sala>(this.apiUrl + 'op/opzioni')
  }

  getItem(id: number): Observable<Sala> {
    return this.http.get<Sala>(this.apiUrl + id)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

}
