import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Video } from './video';
import { GenericItem } from 'src/app/components/class/generic-item';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  apiUrl: string;
  linkBase: string = "video";
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');
  }

  getElenco(): Observable<Video[]> {
    return this.http.get<Video[]>(this.apiUrl)
  }

  getOpzioni(): Observable<Video> {
    return this.http.get<Video>(this.apiUrl + 'op/opzioni')
  }

  getElencoSale(): Observable<GenericItem[]> {
    return this.http.get<GenericItem[]>(this.apiUrl + 'op/elencoSale')
  }

  getItem(id: number): Observable<Video> {
    return this.http.get<Video>(this.apiUrl + id)
  }

  deleteItem(id: number) {
    return this.http.delete<Video>(this.apiUrl + id)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

}
