export class Tariffa {
  id: number;
  denominazione: string;
  prezzo: number
}

export class elencoTariffe {
  items: Tariffa[];
}

