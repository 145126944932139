import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { GenericItem } from 'src/app/components/class/generic-item';
import { elencoPercorsi, Percorso, PercorsoCappello } from './percorso';
import { ParametriCalendario } from '../common-items/parametri-calendario';
import { Parametri } from '../common-items/parametri';
import { ElencoVideo } from './elenco-video';

@Injectable({
  providedIn: 'root'
})
export class PercorsiService {
  apiUrl: string;
  linkBase: string = "percorsi";
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;
  tipologiaCalendario: string = "month";
  arrayCampiCalendario: any[any];

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');
  }

  getElenco(): Observable<elencoPercorsi> {
    return this.http.get<elencoPercorsi>(this.apiUrl)
  }

  getElencoPerCalendario(): Observable<GenericItem[]> {
    return this.http.get<GenericItem[]>(this.apiUrl + 'op/elencoPerCalendario')
  }

  getOpzioni(): Observable<Percorso> {
    return this.http.get<Percorso>(this.apiUrl + 'op/opzioni')
  }

  getCappello(): Observable<PercorsoCappello> {
    return this.http.get<PercorsoCappello>(this.apiUrl + 'op/cappello')
  }

  getElencoTematismi(): Observable<GenericItem[]> {
    return this.http.get<GenericItem[]>(this.apiUrl + 'op/elencoTematismi')
  }

  getElencoPubblico(): Observable<GenericItem[]> {
    return this.http.get<GenericItem[]>(this.apiUrl + 'op/elencoPubblico')
  }

  getElencoVideo(): Observable<ElencoVideo> {
    return this.http.get<ElencoVideo>(this.apiUrl + 'op/elencoVideo')
  }

  getItem(id: number): Observable<Percorso> {
    return this.http.get<Percorso>(this.apiUrl + id)
  }

  deleteItem(id: number) {
    return this.http.delete<Percorso>(this.apiUrl + id)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  sendCappello(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl + 'op/cappello' , body);
  }

  toggleVisibile(id: number) {
    const tipologia = 'visibile';
    return this.http.patch<any>(this.apiUrl + id, {tipologia})
  }

  getParametri(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/parametri')
  }

  clickItemButton(button, item) {
    button.value.onClickFunction(item.id);
  }
}
