import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cambio-lingua',
  templateUrl: './cambio-lingua.component.html',
  styleUrls: ['./cambio-lingua.component.scss']
})
export class CambioLinguaComponent implements OnInit {
  @Input() lingue: any;
  @Output() onChangeLang = new EventEmitter<{value: any}>();

  lingua: string = "";
  constructor() { }

  ngOnInit(): void {
    this.setLingua("ita");
  }

  setLingua(lingua) {
    this.lingua = lingua;
    this.onChangeLang.emit(lingua);
  }
}
