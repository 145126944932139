/******
Versione 1.0.20210401
******/

import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { FormInputService } from '../form-input.service';

@Component({
  selector: 'app-campo-text-area',
  templateUrl: './campo-text-area.component.html',
  styleUrls: ['./campo-text-area.component.scss']
})
export class CampoTextAreaComponent implements OnInit {
  inputClass: string;
  noteClass: string;
  arrayDati: any[] = [];

  constructor(
    private formComponentsService: FormInputService
    ) {
      this.inputClass = this.formComponentsService.inputClass;
      this.noteClass = this.formComponentsService.noteClass;
  }

  @Input() myForm: FormGroup;
  @Input() config: any;
  @Input() idSuffix: string;
  @Input() lang: string = "";

  getInput(id) {
    return this.myForm.get(id);
  }

  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = 'testo';
    }
    if (this.idSuffix) {
      this.config.id += '_' + this.idSuffix;
    }
    if (!this.config.nome) {
      this.config.nome = 'Testo';
    }

    if (!this.config.lingue) {
      this.config.lingue = false;
    }

    if (!this.config.lingue) {
      this.config.lingue = [''];
    }

    this.config.lingue.forEach(lingua => {
      let dummy : any = {};
      dummy.lingua = lingua;
      dummy.nome = this.config.nome;
      dummy.required = this.config.required;
      dummy.id = this.config.id;
      if (lingua != '') {
        dummy.nome += " " + lingua.toUpperCase();
        dummy.id += "_" + lingua;
        if (lingua != 'ita') {
          dummy.required = false;
        }
      }

      this.arrayDati.push(dummy);
    });

    if (!this.config.numRows) {
      this.config.numRows = 5;
    }
    if (!this.config.maxlength) {
      this.config.maxlength = 0;
    }
  }
}
