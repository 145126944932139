import { Component, OnInit } from '@angular/core';
import { FaqService } from '../faq.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-faq-elenco',
  templateUrl: './faq-elenco.component.html',
  styleUrls: ['./faq-elenco.component.scss']
})
export class FaqElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;

  constructor(
    private service: FaqService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.myService = this.service;
    let that = this;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo_ita}` },
          { columnDef: 'categoria', header: 'Categoria',    cell: (element: any) => `${element.categoria}`},
        ];
        this.options = {
          linkBase: "faq"
          ,testataButtons: {
            a0_cappello: {
              type: 'custom',
              testo: "Testo 'come funziona'",
              colorClass: 'info',
              icon: 'fas fa-edit',
              actionTooltip: "Gestione testo 'come funziona'",
              ML: true,
              click: this.gestioneCappello.bind(this),
            },
            a1_categorie: {
              type: 'custom',
              testo: 'Categorie',
              colorClass: 'primary',
              icon: 'fas fa-list',
              actionTooltip: 'Gestione categorie',
              ML: true,
              click: this.gestioneCategorie.bind(this),
            },
          }
        }
      });
  }

  ngOnInit(): void {
  }

  gestioneCappello() {
    this.router.navigate(["/faq/cappello"])
  }

  gestioneCategorie() {
    this.router.navigate(["/faq/categorie"])
  }

}
