/******
Versione 1.0.20210401
******/

import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormInputService } from '../form-input.service';

@Component({
  selector: 'app-campo-numero',
  templateUrl: './campo-numero.component.html',
  styleUrls: ['./campo-numero.component.scss']
})
export class CampoNumeroComponent implements OnInit {
  @Output() onChangeEmitter = new EventEmitter<{value: any}>();
  @Input() myForm: FormGroup;
  @Input() config: any;

  inputClass: string;
  noteClass: string;

  constructor(
    private formComponentsService: FormInputService
    ) {
      this.inputClass = this.formComponentsService.inputClass;
      this.noteClass = this.formComponentsService.noteClass;
  }


  get objInput() {
    return this.myForm.get(this.config.id);
  }


  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = 'numero';
    }
    if (!this.config.nome) {
      this.config.nome = 'Numero';
    }
    if (this.config.shortInput == null) {
      this.config.shortInput = true;
    }
    if (this.config.inputClass != null) {
      this.inputClass = this.config.inputClass;
    }

    if (this.config.shortInput) {
      this.inputClass = this.formComponentsService.shortInputClass;
      this.noteClass = this.formComponentsService.shortNoteClass;
    }
  }

  triggerOnUpdate() {
    if (this.objInput.value == '') {
      return;
    }
    this.onChangeEmitter.emit(this.objInput.value);
  }
}
