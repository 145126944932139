import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { MacroCalendario } from '../../common-items/macro-calendario';
import { CalendarioService } from '../calendario.service';
import { Percorso } from '../../percorsi/percorso';
import { forkJoin } from 'rxjs';
import { PercorsiService } from '../../percorsi/percorsi.service';
import { GenericItem } from 'src/app/components/class/generic-item';


@Component({
  selector: 'app-calendario-scheda',
  templateUrl: './calendario-scheda.component.html',
  styleUrls: ['./calendario-scheda.component.scss']
})
export class CalendarioSchedaComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  myItem: MacroCalendario;
  titoloScheda: string = "Caricamento...";
  linkBase: string = "/calendario";
  modifica: boolean = false;
  giorniSettimana: any[];
  tipologiaInserimento: number = 0;
  operazione: any = '';
  arrayPercorsi: GenericItem[];
  arrayCampi: any[];

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private route: ActivatedRoute,
    private mainEngineService: MainEngineService,
    public service: CalendarioService,
    private notificationsService: NotificationsService,
    private componentsService: ComponentsService,
    private percorsiService: PercorsiService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.giorniSettimana = [
      {'id': 1, 'denominazione': "Lunedì"},
      {'id': 2, 'denominazione': "Martedì"},
      {'id': 3, 'denominazione': "Mercoledì"},
      {'id': 4, 'denominazione': "Giovedì"},
      {'id': 5, 'denominazione': "Venerdì"},
      {'id': 6, 'denominazione': "Sabato"},
      {'id': 7, 'denominazione': "Domenica"},
    ]
  }

  ngOnInit(): void {
    this.arrayCampi = this.service.arrayCampiCalendario;
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);

    forkJoin([
      this.percorsiService.getElencoPerCalendario(),
      this.service.getParametri()
    ])
    .subscribe(
      ([arrayPercorsi,data]) => {
      this.arrayPercorsi = arrayPercorsi;
      delete data.id;
      this.myItem = data;
      this.myForm.patchValue(this.myItem);
      this.myForm.markAsPristine();
      this.titoloScheda = "Gestione calendario";
      this.formComponentsService.activateLoader(false);

    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });

    this.onClickTipologiaInserimento();
  }


  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }


    if (this.myForm.controls['tipologiaInserimento'].value == "1") {
      let value = this.myForm.controls['orariManuali'].value;
      let myArray = value.split(",");
      let regexp = new RegExp(this.formComponentsService.timePatter);
      myArray.forEach( (element) => {
        if (!regexp.test(element)) {
          this.notificationsService.show('error', 'Errore: Il campo "Orari di inizio" non è compliato correttamente. Seguire le istruzioni indicate a fianco della casella');
          return;
        }

    });
    }

    this.loading = true;

    this.service.sendMacroCalendario(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.router.navigate([this.linkBase]);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  formReady(ready: boolean) {
    this.formComponentsService.activateLoader(!ready);
  }

  onCancel() {
    this.componentsService.onBack(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onClickOperazione() {
    this.operazione = this.myForm.getRawValue().operazione;

    let arrayCampi = ['capienza', 'durata', 'esclusiva', 'idPercorsi', 'tipologiaInserimento'];

    arrayCampi.forEach( (campo) => {
      this.myForm.patchValue({campo: ''});
      this.myForm.get(campo).clearValidators();
    });

    switch (this.operazione) {
      case 1: // inserisci
        arrayCampi.forEach( (campo) => {
          this.myForm.get(campo).setValidators([Validators.required]);
          this.myForm.get(campo).updateValueAndValidity();
        });
        break;

      case 0: // cancella
        arrayCampi.forEach( (campo) => {
          this.myForm.patchValue({campo: ''});
          this.myForm.get(campo).clearValidators();
          this.myForm.get(campo).updateValueAndValidity();
        });
        let campo = 'orariManuali';
        this.myForm.get(campo).setValidators([Validators.required]);
        this.myForm.get(campo).updateValueAndValidity();

      break;
    }
  }


  onClickTipologiaInserimento(reset: boolean = false) {

    if (reset) {
      this.tipologiaInserimento = 0;
      this.myForm.patchValue({'tipologiaInserimento': '0'});
    }
    else {
      this.tipologiaInserimento = this.myForm.getRawValue().tipologiaInserimento;
    }
/*
1: manuale
2: automatico
*/

    // rimuovo oraInizio
    this.myForm.patchValue({'oraInizio': ''});
    this.myForm.get('oraInizio').clearValidators();
    // rimuovo oraFine
    this.myForm.patchValue({'oraFine': ''});
    this.myForm.get('oraFine').clearValidators();
    // rimuovo orariManuali
    this.myForm.patchValue({'orariManuali': ''});
    this.myForm.get('orariManuali').clearValidators();

    switch (this.tipologiaInserimento) {
      case 1: // manuale
        this.myForm.get('orariManuali').setValidators([Validators.required]);
        break;

      case 2: // automatico
        this.myForm.get('oraInizio').setValidators([Validators.required]);
        this.myForm.get('oraFine').setValidators([Validators.required]);
        break;
    }

    this.myForm.get('orariManuali').updateValueAndValidity();
    this.myForm.get('oraInizio').updateValueAndValidity();
    this.myForm.get('oraFine').updateValueAndValidity();
  }
}
